<template>
  <div class="swiper-box">
    <swiper
      :modules="modules"
      :loop="true"
      @swiper="onSwiper"
      :thumbs="{ swiper: thumbsSwiperRef }"
      class="carousel carousel-main"
    >
      <swiper-slide
        v-for="(item, idx) in datas"
        :key="idx"
        class="carousel-item"
      >
        <div class="carousel-item-card">
          <el-image
            :src="item.img_url"
            alt=""
            :preview-src-list="datas.map((n) => n.img_url)"
            :initial-index="idx"
            fit="cover"
            preview-teleported
          />
        </div>
      </swiper-slide>
    </swiper>
    <swiper
      :slidesPerView="4"
      :spaceBetween="3"
      :modules="modules"
      @swiper="setThumbsSwiper"
      :watchSlidesProgress="true"
      class="carousel-slide"
    >
      <swiper-slide
        v-for="(item, idx) in datas"
        :key="idx"
        class="carousel-item"
      >
        <div class="carousel-item-card">
          <img class="" :src="item.img_url" alt="" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import {
  FreeMode,
  Autoplay,
  Pagination,
  Scrollbar,
  Navigation,
  Thumbs
} from 'swiper'

const props = defineProps({
  datas: {
    type: [Array, Object],
    default: () => []
  }
})
const swiperRef = ref()
const thumbsSwiperRef = ref()
const total = ref(0)
const loading = ref(false)
const modules = [FreeMode, Autoplay, Pagination, Scrollbar, Navigation, Thumbs]
const onSwiper = (e) => {
  swiperRef.value = e
}
const setThumbsSwiper = (e) => {
  thumbsSwiperRef.value = e
}
const init = () => {}
init()
</script>

<style lang="scss" scoped>
.swiper {
  &-box {
    width: 100%;
  }
}
.carousel {
  &-main {
    width: 465px;
    height: 465px;
    margin-bottom: 5px;
    :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-slide {
    height: 116px;
    cursor: pointer;
    :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    // :deep(.carousel-item-card) {
    //   filter: brightness(0.5);
    // }
    // :deep(.swiper-slide-thumb-active) {
    //   .carousel-item-card {
    //     filter: none;
    //   }
    // }
  }
  &-item {
    &-card {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
