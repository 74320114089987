<template>
  <div class="detail-content">
    <div class="container pt-2" v-loading="loading" v-if="info.name">
      <el-breadcrumb :separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/' }">{{ $t('global.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: 'sort', query: { alias: info.goods_sort_alias, id: info.first_parent_id ?? ''}}">{{ info.goods_sort_title }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.name }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="detail pt-5 mb-5">
        <div class="detail-images">
          <GoodsSwiper :datas="info.image_items" />
        </div>
        <div class="detail-info pl-3">
          <div class="title-wrap">
            <div class="name">{{ info.name }}</div>
            <!-- <el-button type="primary" size="large" round>报价咨询</el-button> -->
          </div>
          <transition name="slide-fade">
            <div class="goods-contrast"
              v-if="info.contrast_list !== undefined && info.contrast_list.length > 0 && showGoodsContrastTips">
              <el-tag type="warning" closable @click="viewGoodsContrast"
                @close="closeGoodsContrastTips">帮您找到了更优商品，点击查看</el-tag>
            </div>
          </transition>
          <div class="detail-info-inner">
            <div class="detail-info-cell">
              <div class="detail-info-label">售价</div>
              <div>
                <a href="javascript:;" class="detail-info-price">
                  价格加入购物车后，点击去报价咨询
                </a>
              </div>
            </div>
          </div>
          <div class="detail-info-control" v-if="info.supply_scope">
            <div class="detail-info-cell">
              <div class="detail-info-label">供货范围</div>
              <!-- <span>{{ info.supply_scope }}</span> -->
              <span class="division" v-for="(division, index) in divisionList" :key = "index">
                <el-tag type="danger" size="large"  v-if="info.supply_scope.findIndex(item=> division.value == item) != -1">{{ division.label }}</el-tag>
                <el-tag type="info" size="large"  v-else>{{ division.label }}</el-tag>
              </span>
            </div>
          </div>
          <div class="detail-info-control">
            <div class="detail-info-cell">
              <div class="detail-info-label">数量</div>
              <el-input-number v-model="num" :min="1" :max="info.goods_sort_alias == 'clearing_zone' ? info.stock_number : 999999" size="default" @change="handleChange" />
            </div>
          </div>
          <div class="detail-info-control" v-if="info.goods_sort_alias == 'clearing_zone'">
            <div class="detail-info-cell">
              <div class="detail-info-label">库存</div>
              <div>{{ info.stock_number }}</div>
            </div>
          </div>
          <div class="detail-info-ft">
            <el-button type="primary" class="detail-info-ft-btn" size="large" :disabled="disabled"
              @click="onAdd">加入购物车</el-button>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="产品介绍" name="first">
          <div class="content" v-html="info.description"></div>
        </el-tab-pane>
        <el-tab-pane label="商品参数" name="second">
          <div class="content" v-html="info.specification"></div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="商品优化列表" class="goods-contrast-container" custom-class="goods-contrast-container" width="70%"
      @open="handleDialogOpen" v-model="ifShowGoodsContrastDialog">
      <el-table ref="contrastGoodsTable" :data="info.contrast_list || []" border
        :header-cell-class-name="arraySpanMethod">
        <el-table-column property="label.label" width="120px" class-name="contrast-title-column"
          fixed/>
        <el-table-column v-for="i in info.contrast_count" :property="'data_' + i" :key="i">
          <template #header>{{ i == 1 ? '当前商品' : (i <= 2 ? '优化后商品' : '') }} </template>

              <template v-slot="scope">
                <template v-if="scope.row.label.key == 'goods_info'">
                  <div class="goods-info-container">
                    <div class="goods-thumb"><el-image :src="scope.row['data_' + i]['goods_thumb']"/></div>
                    <div class="goods-name" :title="scope.row['data_' + i]['goods_name']">{{ scope.row['data_' +
      i]['goods_name'] }}</div>
                    <div class="goods-price">
                      <div class="money-symbol">&yen;</div>
                      {{ scope.row['data_' + i]['goods_price'] }}
                    </div>
                    <div>
                      <el-button size="small" type="primary" @click="viewGoodsDetail(scope.row['data_' + i])">
                        立即查看
                      </el-button>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <span :class="scope.row['data_' + i]['is_same'] == 0 ? 'diff-value' : ''">{{ scope.row['data_' +
      i]['value']
                    }}</span>
                </template>
              </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { goodsView, getDivisionList } from '@/api/goods'

import { ArrowRight } from '@element-plus/icons-vue'
import GoodsSwiper from './components/goodsSwiper.vue'
import { cartCreate } from '@/api/cart.js'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
const route = useRoute()
const router = useRouter()
const id = computed(() => route.query.id)
const info = ref({})
const loading = ref(false)
const activeName = ref('first')
const num = ref(1)
const divisionList = ref([])

const getDetail = () => {
  loading.value = true
  goodsView({ id: id.value })
    .then((res) => {
      info.value = res.data
      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
}

const getDivisions = () => {
  getDivisionList()
    .then((res) => {
      divisionList.value = res.data
    })
    .catch(() => {
    })
}


const handleClick = (e) => { }

const handleChange = (e) => { }
const disabled = ref(false)
const disabledStatusFalse = (count) => {
  setTimeout(() => {
    disabled.value = false
  }, count * 1000)
}
const store = useStore()
const onAdd = () => {
  const { id } = info.value || {}
  disabled.value = true
  disabledStatusFalse(60)
  cartCreate({
    goods_alias: id,
    goods_number: num.value
  })
    .then((res) => {
      disabledStatusFalse(1)
      const { error, msg } = res
      if (error == 0) {
        ElNotification({
          title: msg || 'success',
          type: 'success'
        })
        num.value = 1
        store.dispatch('app/getCartList')
      }
    })
    .catch(() => {
      disabledStatusFalse(1)
      num.value = 1
    })
}

getDetail()
getDivisions()

const contrastGoodsTable = ref()
const showGoodsContrastTips = ref(true)
const ifShowGoodsContrastDialog = ref(false)
const closeGoodsContrastTips = () => {
  showGoodsContrastTips.value = false
}
const viewGoodsContrast = () => {
  ifShowGoodsContrastDialog.value = true
}
const arraySpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  if (rowIndex == 0 && columnIndex == 2) {
    return 'cols-span'
  } else if (columnIndex > 2) {
    return 'cols-hidden'
  }
}

const handleDialogOpen = () => {
  setTimeout(() => {
    contrastGoodsTable.value.$el.querySelector('.cols-span').colSpan = info.value.contrast_count - 1
  }, 200)
}

const viewGoodsDetail = (item) => {
  const { goods_id } = item || {}
  const routerHref = router.resolve({
    name: 'GoodsDetail',
    query: { id: goods_id }
  })
  window.open(routerHref.href, '_blank')
}

</script>

<style lang="scss" scoped>

:deep(p) {
  margin: 0px !important;
  padding: 0px !important;
}

.el-breadcrumb {
  &__item {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;

  .name {
    font-size: 22px;
    font-weight: 400;
    color: #2c3e50;
    line-height: 1.3;
  }
}

.content {
  min-height: initial;
  text-align: center;

  :deep(img) {
    max-width: 100% !important;
  }
}

.detail {
  display: flex;

  &-content {
    background-color: #fff;
  }

  &-images {
    width: 465px;
  }

  &-info {
    // font-size: 20px;
    flex: 1;

    &-inner {
      background-color: #fff;
      padding-top: 10px;
      padding-bottom: 1px;
      margin: 10px 0px;
    }

    &-cell {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      min-height: 42px;
      // padding-left: 20px;
    }

    &-label {
      min-width: 130px;
    }

    span {
      font-weight: bold;
      // color: #de0a0a;
      font-size: 16px;
    }

    .el-tag.el-tag--info{
      --el-tag-text-color:rgba(17,20,24,.2)
    }

    &-price {
      // font-size: 28px;
      // margin-left: -5px;
      // color: var(--el-color-danger);
      color: #2c3e50;
      // margin-left: -80px;
    }

    &-ft {
      padding-top: 10px;

      &-btn {
        // width: 200px;
        // height: 60px;
        font-size: 18px;
      }
    }
  }
}

.goods-contrast {
  margin: 10px 0;
  transition: all ease 1s;

  .el-tag {
    cursor: pointer;
    font-size: 14px;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
  {
  transform: translateX(-20px);
  opacity: 0;
}

.goods-contrast-container {
  .goods-info-container {
    .goods-thumb .el-image {
      width: 150px;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .goods-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .goods-price {
      font-size: 18px;
      color: #c00000;
      line-height: 30px;
      display: flex;
      align-items: baseline;

      .money-symbol {
        font-size: 14px;
      }
    }

    a {
      color: #fff;
    }
  }

  .diff-value {
    color: #ffb200;
    font-weight: 600;
  }

  .el-table {
    :deep(.el-table__inner-wrapper) {
      .el-table__header-wrapper {
        // thead{
        //   .el-table__cell{
        //     background-color: #e7e7e7;
        //   }
        // }

        .cols-span {
          text-align: center;
        }
      }
    }

    :deep(.contrast-title-column) {
      background-color: #e7e7e7;
    }
  }
}
</style>

<style></style>
